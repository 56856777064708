import React from 'react';
import Box from '@mui/material/Box';
import logo from '../images/breathguardian.png';

export default function SitemarkIcon() {
  return (
    <Box>
      <a href="https://uad.cnl.sk">
        <img src={logo} alt="Sitemark" style={{ width: 120, height: 40, marginRight: 20 }} />
      </a>
    </Box>
  );
}
