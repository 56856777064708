import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';
import CloudDownload from '@mui/icons-material/CloudDownload';
import HowToReg from '@mui/icons-material/HowToReg';
import MobileFriendly from '@mui/icons-material/MobileFriendly';

const userTestimonials = [
  {
    avatar: <CloudDownload/>,
    name: 'Spustenie aplikácie',
    testimonial: `1. Stiahnite si aplikáciu z obchodu Google Play, priamo APK súbor pre Android zariadenia alebo otvorte aplikáciu na mobilnom zariadení bez sťahovania. ( Viac informácií nájdete v sekcii "Používanie aplikácie" )`
  },
  {
    avatar: <HowToReg/>,
    name: 'Registrácia užívateľa',
    testimonial:
      "2. Po spustení aplikácie sa zaregistrujte ako nový užívateľ, kde vyberiete svojho špecialistu. Po úspešnej registrácii overte prosím svôj email a následne sa prosím prihláste do aplikácie.",
  },
  {
    avatar: <MobileFriendly/>,
    name: 'Používanie aplikácie',
    testimonial:
      '3. Po prihlásení sa je možné v intervaloch odosielať dotazník ako sa cítite, pričom výsledky sú viditeľné pre Vás aj Vášho špecialistu. Dostupné sú aj informácie o peľovej situácií vo Vašom okolí.',
  },
];

const logoStyle = {
  width: '64px',
  opacity: 0.3,
};

export default function Testimonials() {
  const theme = useTheme();

  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" sx={{ color: 'text.primary' }}>
          Ako to funguje
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          Pre správne fungovanie aplikácie je potrebné dodržiavať nasledujúce kroky: 
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
                p: 1,
              }}
            >
              <CardContent>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  pr: 2,
                }}
              >
                <CardHeader
                  avatar={testimonial.avatar}
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
